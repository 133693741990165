import "./LoginModal.scss";
import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCloseCircle } from "react-icons/io5";
import { Button, Modal } from "react-bootstrap";
import RestClient from "../../../../@core/restClient";
import { jwtDecode } from "jwt-decode";

// interface LoginModalProps {
//   show: boolean;
//   onHide: () => void;
// }

interface LoginData {
    email: string;
    password: string;
}

type LoginDataState = [
    loginData: LoginData,
    setLoginData: (loginData: LoginData) => void
];
const LoginModal = () => {

    const [loginData, setLoginData]: LoginDataState = useState({ email: "", password: "" });
    const [registrationModal, setRegistrationModal] = useState(false);
    const [registrationData, setRegistrationData] = useState({ email: "", password: "", firstName: "", lastName: "" });
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    // const login = () => {
    //   console.log("data", loginData);
    //   localStorage.setItem("Token", "develop-auth-token");
    //   props.onHide();
    // };

    const login = async () => {
        try {
            console.log("data", loginData);
            const response = await RestClient.login(loginData);
            console.log("response", response);
            localStorage.setItem("Token", response.token);
            const decodedToken: any = jwtDecode(response.token);
            localStorage.setItem("User", JSON.stringify(decodedToken));
            navigate(-1);
            //closeModal();
        } catch (error: any) {
            console.log(error.response.data.message);
            setErrorMessage("Непривийльний email або пароль");
            setErrorModal(true);
        }


    };

    const register = async () => {
        try {
            console.log("data", loginData);
            const response = await RestClient.register(registrationData);
            console.log("response", response);
            localStorage.setItem("Token", response.token);
            const decodedToken: any = jwtDecode(response.token);
            localStorage.setItem("User", JSON.stringify(decodedToken));
            setRegistrationModal(false);
            navigate(-1);
            //closeModal();
        } catch (error: any) {
            console.log(error.response.data.message);
            // closeModal();
            //setRegistrationModal(false);
            if (error.response.data.message === "User already exists") {
                setErrorMessage("Користувач з такою адресою вже існує");
                setErrorModal(true);
            } else {
                setErrorMessage("Щось пішло не так, спробуйте ще раз");
                setErrorModal(true);
            }

        }
    };

    const openRegistration = () => {
        setRegistrationModal(true);
    };

    const closeRegistration = () => {
        setRegistrationModal(false);
        //closeModal();
    };





    return (
        <div className="login-modal">
            {!registrationModal ?
                <div className="login-modal-box">
                    <div className="login-input">
                        <div className="login-input-item">
                            <input type="text"
                                placeholder="Введіть email"
                                value={loginData.email}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setLoginData(
                                    { ...loginData, email: event.target.value }
                                )}
                            />
                        </div>
                        <div className="login-input-item">
                            <input type="text"
                                placeholder="Введіть пароль"
                                value={loginData.password}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setLoginData(
                                    { ...loginData, password: event.target.value }
                                )}
                            />
                        </div>
                    </div>
                    <div className="btn-box">
                        <button className="btn-mcolor" onClick={login}>Увійти</button>
                        <button className="btn-forget-password">Забули пароль?</button>
                    </div>
                    <div className="invite">
                        <p className="invite-text">Якщо ви не зареєстровані, тисніть <button className="btn-plain invite-text-btn" onClick={openRegistration}>Сюди</button>  </p>
                    </div>
                    <IoCloseCircle className="closeIcon" onClick={() => navigate(-1)} />
                    {errorModal ?


                        <div className="error-box">
                            <p className="error-text">{errorMessage}</p>
                            <IoCloseCircle className="closeIcon" onClick={() => setErrorModal(false)} />

                        </div>


                        : null

                    }
                </div>
                :
                <div className="login-modal-box">
                    <div className="login-input">
                        <div className="login-input-item">
                            <input type="text"
                                placeholder="Ім'я"
                                value={registrationData.firstName}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setRegistrationData(
                                    { ...registrationData, firstName: event.target.value }
                                )}
                            />
                        </div>
                        <div className="login-input-item">
                            <input type="text"
                                placeholder="Прізвище"
                                value={registrationData.lastName}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setRegistrationData(
                                    { ...registrationData, lastName: event.target.value }
                                )}
                            />
                        </div>
                    </div>


                    <div className="login-input">
                        <div className="login-input-item">
                            <input type="text"
                                placeholder="Email"
                                value={registrationData.email}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setRegistrationData(
                                    { ...registrationData, email: event.target.value }
                                )}
                            />
                        </div>
                        <div className="login-input-item">
                            <input type="text"
                                placeholder="Пароль"
                                value={registrationData.password}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setRegistrationData(
                                    { ...registrationData, password: event.target.value }
                                )}
                            />
                        </div>
                    </div>
                    <div className="btn-box">
                        <button className="btn-mcolor" onClick={register}>Зареєструватись</button>
                    </div>

                    <IoCloseCircle className="closeIcon" onClick={closeRegistration} />
                    {errorModal ?


                        <div className="error-box">
                            <p className="error-text">{errorMessage}</p>
                            <IoCloseCircle className="closeIcon" onClick={() => { setErrorModal(false); navigate(-1); }} />

                        </div>


                        : null

                    }

                </div>


            }


        </div>
    );
};
export default LoginModal;
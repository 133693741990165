import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Cabinet from "../../../pages/Cabinet/Cabinet";
import Error from "../../../pages/Error/Error";
import Home from "../../../pages/Home/Home";
import SongbookPage from "../../../pages/Songbook/Songbook";
import LoginModal from "../dynamic/LoginModal/LoginModal";
import "./Content.scss";

const Content = () => {
  return (
    <section>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cabinet" element={<Cabinet />} />
        <Route path="/songbook/:songbookId" element={<SongbookPage />} />
        <Route path="/songbook/:songbookId/song/:songId" element={<SongbookPage />} />
        <Route path="/error" element={<Error />} />
        <Route path="/loginmodal" element={<LoginModal />} />

        <Route path="*" element={<Navigate replace to="/error" />} />
      </Routes>
    </section>
  );
};

export default Content;

import React, { ChangeEvent, useState, useEffect } from "react";
import "./PersonalData.scss";
import { IoCheckmark, IoEye, IoEyeOff } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { divide } from "lodash";
import RestClient from "../../@core/restClient";
import { jwtDecode } from "jwt-decode";

const PersonalData = () => {
  const [userId, setUserId] = useState("");
  const [changeData, setChangeData] = useState({});
  const [messageOk, setMessageOk] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    setUserId(JSON.parse(localStorage.getItem("User") || "{}")?.id);
  }, []);
  const [typeOld, setTypeOld] = useState("password");
  const [typeNew, setTypeNew] = useState("password");
  const [eyeOld, setEyeOld] = useState("off");
  const [eyeNew, setEyeNew] = useState("off");

  const toggleOld = () => {
    if (typeOld === "password") {
      setEyeOld("on");
      setTypeOld("text");
    } else {
      setEyeOld("off");
      setTypeOld("password");
    }
  };
  const toggleNew = () => {
    if (typeNew === "password") {
      setEyeNew("on");
      setTypeNew("text");
    } else {
      setEyeNew("off");
      setTypeNew("password");
    }
  };

  // const changeRegister = async () => {
  //   try {
  //     console.log("data", changeData);
  //     const response = await RestClient.changeRegister(userId, changeData);
  //     console.log("response", response);
  //     localStorage.setItem("Token", response.token);
  //     const decodedToken: any = jwtDecode(response.token);
  //     localStorage.setItem("User", JSON.stringify(decodedToken));
  //     setMessageOk(true);
  //     setChangeData({});
  //   } catch (error: any) {
  //     console.log(error.response.data.message);
  //     setMessageError(true);
  //   }
  // };

  return (
    <div className="pdata">
      <p className="pdata-text">
        Для зміни реєстраційних даних заповніть ті поля, які хочете змінити й
        натисніть &quot;відправити&quot;. Поле &quot;Діючий пароль&quot; -
        обов&apos;язкове.
      </p>
      <div className="pdata-form">
        <div className="pdata-form-inputs">
          <label htmlFor="firstName">Ім&apos;я</label>
          <input
            type="text"
            name="firstName"
            //value={""}
            //value={changeData.firstName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setChangeData({ ...changeData, firstName: event.target.value })
            }
          />
          <label htmlFor="lastName">Прізвище</label>
          <input
            type="text"
            name="lastName"
            //value={""}
            //value={changeData.lastName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setChangeData({ ...changeData, lastName: event.target.value })
            }
          />
          <label htmlFor="name">Електронна адреса</label>
          <input
            type="email"
            name="name"
            // value={""}
            //value={changeData.email}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setChangeData({ ...changeData, email: event.target.value })
            }
          />
          <label htmlFor="oldPassword">
            Діючій пароль <span>*</span>
            {eyeOld === "off" ? (
              <IoEyeOff className="eyeIcon" onClick={toggleOld} />
            ) : (
              <IoEye className="eyeIcon" onClick={toggleOld} />
            )}
          </label>
          <input
            type={typeOld}
            name="oldPassword"
            //value={""}
            //value={changeData.password}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setPassword(event.target.value)
            }
          />
          <label htmlFor="newPassword">
            Новий пароль{" "}
            {eyeNew === "off" ? (
              <IoEyeOff className="eyeIcon" onClick={toggleNew} />
            ) : (
              <IoEye className="eyeIcon" onClick={toggleNew} />
            )}{" "}
          </label>
          <input
            type={typeNew}
            name="newPassword"
            //value={""}
            //value={changeData.newPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setChangeData({ ...changeData, password: event.target.value })
            }
          />{" "}
        </div>
        <div className="pdata-form-inputs">
          <button
            className="btn-mcolor pdata-form-btn"
            //onClick={changeRegister}
          >
            Відправити
          </button>
        </div>
      </div>
      {messageOk ? (
        <div className="message">
          <IoCheckmark className="message-i-ok" />
          <p className="message-ok">Дані успішно змінено!</p>
        </div>
      ) : (
        ""
      )}
      {messageError ? (
        <div className="message">
          <ImCross className="message-i-error" />
          <p className="message-error">Невірний пароль, спробуйте ще раз.</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PersonalData;
